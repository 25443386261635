<template>
	<div class="max-w-6xl m-auto">
		<div class="grid w-full grid-cols-1 px-3 lg:flex">
			<div class="w-full pt-2 lg:w-2/3">
				<div id="breadcrumb">
					<nav aria-label="breadcrumb">
						<ol class="flex p-2 px-0 bg-white breadcrumb">
							<li class="text-sm breadcrumb-item">
								<router-link to="/">
									Home
								</router-link>
							</li>
							<li
								v-if="stateName"
								class="text-sm breadcrumb-item"
							>
								<router-link :to="`/${formatStr(stateName)}`">
									{{ stateName }}
								</router-link>
							</li>
							<li
								v-if="cityName"
								class="text-sm capitalize breadcrumb-item"
							>
								<router-link :to="`/${cityName}`">
									{{ formattedCityName }}
								</router-link>
							</li>
							<li
								v-if="cityName"
								class="text-sm breadcrumb-item"
							>
								<span class="font-bold text-green-500">
									Guide
								</span>
							</li>
						</ol>
					</nav>
				</div>

				<h1
					v-if="cityGuide"
					class="mb-3 text-3xl font-bold text-left"
				>
					{{ cityGuide.title }}
				</h1>

				<div class="w-full">
					<img
						:src="mainImage"
						:alt="mainImage"
						height="299"
						width="746"
						class="w-full"
						@error="useDefaultMainImage"
					>
				</div>

				<div
					v-if="hasFeatures"
					class="grid w-full grid-cols-1 mt-4 mb-4 lg:flex lg:space-x-3"
				>
					<div class="w-full lg:w-48">
						<TableOfContents
							:features="features"
							:current-element="currentElement"
							:guide-categories="guideCategories"
							@set-current-element="setCurrentElement"
						/>
					</div>
					<div class="w-full lg:w-auto">
						<div
							v-for="(category, key) in features"
							:key="key"
						>
							<h2
								v-if="hasCategoryTitle(key)"
								:id="formatStr(getCategoryTitle(key))"
								class="pt-2 mb-3 text-2xl text-left capitalize observe"
							>
								{{ getCategoryTitle(key) }} in {{ formattedCityName }}
							</h2>
							<div
								v-for="(article, index) in category"
								:id="formatStr(getCategoryTitle(key)) + '/' + formatStr(article.title)"
								:key="index"
								class="w-full observe"
							>
								<img
									v-if="hasImage(article)"
									:src="setArticleImage(article.image)"
									height="551"
									width="551"
									class="w-full"
									:alt="article.title || 'wheres weed travel guide'"
									@error="useDefaultArticleImage"
								>
								<p
									v-if="hasSource(article)"
									class="mb-3 text-left"
								>
									Source: <a
										class="text-green-500"
										:href="article.sourceUrl"
									>
										{{ article.source }}
									</a>
								</p>

								<div
									v-if="hasTitle(article)"
									class="flex flex-wrap"
								>
									<h3 class="text-left">
										<a
											class="text-2xl text-left text-green-500"
											:class="{'pr-3': article.price !== ''}"
											:href="article.website"
										>
											{{ article.title }}
										</a>
									</h3>
									<h4 v-if="article.price !== ''">
										({{ article.price }})
									</h4>
								</div>

								<p
									v-if="hasTitle(article)"
									class="mb-3 text-left"
									v-html="article.content"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<GuideSidebar class="w-full md:pl-3" />
		</div>
	</div>
</template>

<script async>
import { GUIDE_IMAGES } from '@/constants/images/image-folder-paths.js'
import { CityGuide } from '@/gql/city/queries/pages.gql'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'
import { componentLevelGQLErrors, logError } from '@/utils/error-handling.js'

export default {
	components: {
		GuideSidebar: () => import('./components/Sidebar.vue'),
		TableOfContents: () => import('./components/TableOfContents.vue')
	},
	data() {
		return {
			defaultMainImage: 'https://wheresweed.com/guide_images/1680x/denver-marijuana-travel-guide.png',
			defaultArticleImage: 'https://wheresweed.com/guide_images/full/16th-street-mall.png',
			currentElement: '',
			overrideIO: false,
			guideCategories: [
				'Where to visit',
				'What to do',
				'Where to Buy Marijuana',
				'Where to Shop / Arts',
				'Where to Eat'
			],
			isMounted: false
		}
	},
	computed: {
		hasFeatures() {
			return !!this.features
		},
		features() {
			return this.cityGuide?.features
		},
		hasGuideImage() {
			return !!this.cityGuide?.primaryImage
		},
		mainImage() {
			if (this.cityGuide?.primaryImage) {
				return buildImageUrl({
					imagePath: GUIDE_IMAGES, full: true, file: this.cityGuide.primaryImage
				})
			}
			return ''
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		},
		cityName() {
			return this.cityGuide?.cityName || ''
		},
		stateName() {
			return this.cityGuide?.state?.name || ''
		},
		formattedCityName() {
			return this.cityName === 'washington-dc' ? 'Washington DC' : this.cityName.replace('-', ' ')
		}
	},
	watch: {
		isMounted: {
			handler() {
				if (!this.isMounted) return
				try {
					if (this.hasFeatures && this.browserSupportsIO) {
						const observer = new IntersectionObserver(this.scrollSpied)
						const scrollTargets = document.getElementsByClassName('observe')

						for (const element of scrollTargets) {
							observer.observe(element)
						}
					}
				} catch (e) {
					logError(e)
				}
			},
			immediate: true
		}
	},
	mounted() {
		if (this.$route.hash) {
			this.currentElement = this.$route.hash.substring(1)
			this.overrideIO = true
			if (!this.hasFeatures) return
			const scrollToElement = document.getElementById(this.currentElement)
			if (scrollToElement) {
				scrollToElement.scrollIntoView()
			}
		}
		window.onhashchange = () => {
			this.overrideIO = true // set this so the item clicked is the one active.  It stops the intersection observer from setting it
			this.currentElement = this.$route.hash.substring(1)
		}
		this.isMounted = true
	},
	methods: {
		setCurrentElement(sectionName, articleName) {
			this.currentElement = sectionName + '/' + articleName // set active article on click
		},
		scrollSpied: function (entries) {
			if (this.overrideIO === true) {
				this.overrideIO = false
				return
			}

			for (const i in entries) {
				if (entries[i].isIntersecting === true) {
					this.currentElement = entries[i].target.id
					return
				}
			}
		},
		setArticleImage(image) {
			if (image && image !== '') {
				return buildImageUrl({
					imagePath: GUIDE_IMAGES, full: true, file: image
				})
			}
			return ''
		},
		formatStr: function (string) {
			const toKebabCase = str =>
				str &&
				str
					.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
					.map(x => x.toLowerCase())
					.join('-')
			return toKebabCase(string)
		},
		useDefaultMainImage(event) {
			event.target.src = this.defaultMainImage
		},
		useDefaultArticleImage(event) {
			event.target.src = this.defaultArticleImage
		},
		hasTitle(article) {
			return !!article.title?.length
		},
		hasImage(article) {
			return !!article.image?.length
		},
		hasSource(article) {
			return !!article.source
		},
		getCategoryTitle(category) {
			return this.guideCategories.find(element => element.toLowerCase().includes(category))
		},
		hasCategoryTitle(key) {
			return !!this.getCategoryTitle(key)
		}
	},
	apollo: {
		cityGuide: {
			query: CityGuide,
			update(data) {
				if (data.meta) { this.$store.commit('setMeta', data.meta) }
				if (data.auth) { this.$store.commit('auth/setAuth', { auth: data.auth }) }
				if (data.cityGuide) {
					return {
						cityName: data.cityGuide.seoUrl,
						state: data.cityGuide.state,
						title: data.cityGuide.title,
						primaryImage: data.cityGuide.primaryImage,
						features: data.cityGuide.content?.features
					}
				}
			},
			variables () {
				return {
					city: this.$route.params.city
				}
			},
			error(error) {
				componentLevelGQLErrors(error)
				return false
			},
			errorPolicy: 'all'
		}
	}
}
</script>

<style lang="scss" scoped>
.breadcrumb {
	.breadcrumb-item + .breadcrumb-item::before {
		content: ">";
		padding-left: 0.25rem;
	}
}
</style>
